import { useState, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';

export default function Select({
  valueDefault = '', options = [], onSelected, label, className, placeholder,
}) {
  // eslint-disable-next-line max-len
  const [valueSelected, setValueSelected] = useState(options.find((o) => o.value === valueDefault) || {});

  useEffect(() => {
    if (onSelected) onSelected(valueSelected);
  }, [valueSelected]);

  return (
    <Listbox as="div" className="" value={valueSelected.value} onChange={setValueSelected}>
      {({ open }) => (
        <>
          {!label || (
          <Listbox.Label className="block leading-6 text-dark">
            {label}
          </Listbox.Label>
          )}
          <div className={`relative ${className}`}>
            <span className="inline-block w-full py-1 rounded-md lg:py-0">
              <Listbox.Button className="relative w-full pr-10 text-left transition duration-150 ease-in-out bg-white border rounded-md cursor-pointer border-white-dark4 focus:outline-none focus:border-white-dark4 sm:leading-6">
                {(!Object.entries(valueSelected || {}).length || !valueSelected.value) && placeholder ? <>{placeholder}</>
                :
                <>
                {valueSelected.contentSelected || (valueSelected.content || <span className="block text-base truncate">{valueSelected.title}</span>)}
                </>}
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <svg className="w-5 h-5 text-dark" viewBox="0 0 20 20" fill="none" stroke="currentColor">
                    <path d="M7 7l3-3 3 3m0 6l-3 3-3-3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
              </Listbox.Button>
            </span>
            <Transition
              as="div"
              show={open}
              enter="transition ease-in duration-400"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition ease-in duration-400"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-button"
            >
              <Listbox.Options static className="py-1 overflow-auto text-base leading-6 rounded-md shadow-xs max-h-60 focus:outline-none sm:leading-5">
                {options.map((option) => (
                  <Listbox.Option key={option.value} value={option}>
                    {({ selected, active }) => (
                      <div
                        className={`${active ? 'text-black bg-white-dark inline-block w-full rounded-md' : 'text-dark'} cursor-pointer relative`}
                      >
                        {option.content || (
                        <span className={`${selected ? 'font-medium' : 'font-normal'} block truncate`}>
                          {option.title}
                        </span>
                        )}
                        {selected && (
                        <span className={`${active ? 'text-white' : 'text-blue'} absolute inset-y-0 left-0 flex items-center pl-1.5`}>
                          <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                        )}
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
